<template>
  <div v-if="event" id="page" :style="buttonColor">
    <CreateOrderActiveBanner v-if="showCreateOrderBanner"> </CreateOrderActiveBanner>
    <Analytics v-if="event.settings" :pixel-code="event.settings.pixelCode" :gtm-code="event.settings.gtmCode" />
    <UserLocation
      v-if="event.province && geoLocationEnabled"
      ref="userLocation"
      :event-province="event.province"
      :country="event.country"
      :sessionId="sessionId"
      :event="event"
      @userLocationFound="userLocationFound"
    />
    <AppNavV2 v-if="displayAppNav && phase1 && !disableOnlineSales" />
    <AppNav v-if="displayAppNav && !phase1 && !disableOnlineSales" />
    <main class="h-100 d-flex flex-column">
      <Maintenance v-if="maintenance" />
      <div
        v-else-if="loadingPage && !errorCode"
        class="d-flex align-items-center justify-content-center flex-column mb-3 mt-2"
      >
        <b-spinner class="big-spinner" variant="secondary"></b-spinner>
      </div>
      <b-container v-else fluid="md">
        <b-row>
          <b-col lg="8" offset-lg="2" class="col-xxl-6 offset-xxl-3">
            <StepNav
              v-if="displayStepNav && !showMultiAddonFlow"
              :first-title="formatStepTitle(event)"
              :second-title="formatStepTitle(event.childEvents[0])"
            />
            <ErrorHandler v-if="errorCode" :error="errorCode" :sessionId="sessionId" />
            <RbAlert v-if="event.settings && disableOnlineSales" variant="info" icon="exclamation-circle" class="mt-5">
              {{ $t('onlineSalesDisabled') }}
            </RbAlert>
            <RbAlert v-if="locationLink" variant="info" icon="question-circle">
              Find out how to enable location services
              <a
                href="https://support.rafflebox.ca/hc/en-us/articles/5197879838349"
                target="_blank"
                style="color: #8075ff; font-weight: bold"
                rel="noopener"
                >here</a
              >.
            </RbAlert>
            <router-view v-if="!errorCode && !disableOnlineSales" />
            <PurchaseBar
              v-if="
                !errorCode &&
                $route.name !== 'Payment' &&
                $route.name !== 'Success' &&
                $route.name !== 'Status' &&
                !disableOnlineSales
              "
              :disable-next="event.settings.requirePurchase && isCartEmpty"
            />
            <RbResponsibleGamingBanner
              v-if="event.province === 'BC'"
              :license-number="event.licenseNumber"
              class="p-0 mt-4 mb-3"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import { Event } from '@/lib/schema';
import { EventStatus, Merchant, TicketPackage, EventCheckoutType, WhiteLabelSettings } from '@/lib/schema/event.schema';
import { CountryProvinceAgeModel, Country, ErrorCodes } from '@rafflebox-technologies-inc/rafflebox-schema';

import config from '@/config';

import ProjectMixin from '@/project-mixin';
import CreateOrderActiveBanner from './components/CreateOrderActiveBanner.vue';
import { EventService } from '@/lib/services';
import UserLocation from '@/components/UserLocation.vue';
import AppNav from '@/components/AppNav.vue';
import AppNavV2 from '@/components/AppNavV2.vue';
import StepNav from '@/components/StepNav.vue';
import PurchaseBar from '@/components/PurchaseBar.vue';
import ErrorHandler from '@/components/ErrorHandler.vue';
import Analytics from '@/components/Analytics.vue';
import Maintenance from '@/components/Maintenance.vue';
import RbAlert from '@/components/RbAlert.vue';
import { CheckoutErrorCodes } from './lib/error-codes';
import { unleashFeatureEnabled, UnleashKeys, updateUnleashContext } from '@/lib/unleash';
import { v4 as uuidv4 } from 'uuid';

interface IUserLocation {
  userLocationProvince: string;
}

@Component({
  components: {
    AppNav,
    AppNavV2,
    StepNav,
    PurchaseBar,
    UserLocation,
    ErrorHandler,
    Analytics,
    Maintenance,
    RbAlert,
    CreateOrderActiveBanner
  }
})
export default class App extends Mixins(ProjectMixin) {
  geoLocationEnabled = false;
  errorCode: ErrorCodes | string = '';
  event: Event = this.$store.state.event;
  siteUrl = config.RAFFLEBOX_URL;
  locationLink = false;
  loadingPage = true;
  showCreateOrderBanner = false;
  // Use this flag to turn on maintenance page and block payment routes.
  maintenance = false;
  phase1 = false;
  disableOnlineSales = false;
  sessionId = uuidv4();
  canViewMultiAddOnFlowOrgs = false;

  async created() {
    this.showCreateOrderBanner = this.$route.query.createOrder === 'true';

    this.$store.dispatch('setShowCreateOnlineOrderButton', this.showCreateOrderBanner);
    this.$store.dispatch('setRedirectUrl', origin + this.$route.fullPath);

    if (this.$route.name != 'Success') {
      await this.fetchEvent();
    } else {
      this.loadingPage = false;
    }

    // check for disable online sales
    if (this.event.settings && this.event.settings.disableOnlineSales) {
      this.disableOnlineSales = true;
    }

    const enablePreviewTickets = await unleashFeatureEnabled(UnleashKeys.PreviewTickets);
    this.$store.commit('setEnablePreviewTickets', enablePreviewTickets);

    this.canViewMultiAddOnFlowOrgs = await unleashFeatureEnabled(UnleashKeys.ViewMultiAddOnFlowOrgs);
  }

  get displayAppNav() {
    return !this.loadingPage && !this.errorCode;
  }

  get displayStepNav() {
    return (
      !this.errorCode &&
      this.$route.name !== 'Status' &&
      this.$route.name !== 'Payment' &&
      this.$route.name !== 'Success' &&
      this.$route.name !== 'Single' &&
      this.$route.name !== 'Goldrush'
    );
  }

  get showMultiAddonFlow() {
    return this.canViewMultiAddOnFlowOrgs;
  }

  get buttonColor() {
    return this.$store.getters.buttonColor;
  }

  get isCartEmpty() {
    return this.shoppingCart.totalCents === 0;
  }

  formatStepTitle(event: Event) {
    // TODO: Move to step nav component
    let title = '50/50';
    if (event.category === 'Raffle') {
      title = 'Prize Raffle';
    }

    return title;
  }

  childEventSalesEnabled(event: Event) {
    return event.childEvents.filter((event) => event.salesEnabled);
  }

  salesEnabled(event: Event) {
    return event.salesEnabled && this.childEventSalesEnabled(event);
  }

  async fetchEvent() {
    if (this.$route.name === 'Status') {
      this.loadingPage = false;
      return;
    }

    try {
      // Use query parameters or use store
      const eventId = (this.$store.state.event.id as string) || (this.$route.query.eventId as string);
      if (!eventId) {
        throw new Error(CheckoutErrorCodes.MissingEventIdParameter);
      }

      // Fetch Data
      const event = await EventService.readEvent(eventId);

      // Ensures proper URL for Raffle dependant on Type
      this.eventCategoryTypeRedirect(event);

      this.$root.$i18n.locale = (this.$route.query.locale as string) === 'fr' ? 'fr' : 'en';

      // Event Data Errors
      if (event.status !== EventStatus.Active) {
        throw new Error(ErrorCodes.EventNotActive);
      }

      if (event.childEvents.length > 0 && event?.childEvents[0]?.status !== EventStatus.Active) {
        throw new Error(ErrorCodes.EventNotActive);
      }

      if (event.merchant !== Merchant.StripeConnect) {
        throw new Error(ErrorCodes.MerchantNotSupported);
      }

      if (!event.stripeConnectedAccountId) {
        throw new Error(ErrorCodes.StripeMissingAccountId);
      }

      if (!event.province) {
        throw new Error(ErrorCodes.ProvinceNotFound);
      }

      // If any of the child raffles have sales enabled do not show this error
      if (!this.salesEnabled(event)) {
        throw new Error(CheckoutErrorCodes.SalesDisabled);
      }

      this.event = event;

      // pass the organization id to the unleash context
      await updateUnleashContext({ organizationId: this.event.organizationId });

      this.phase1 = unleashFeatureEnabled(UnleashKeys.Phase1);
      this.$store.commit('setPhase1', this.phase1);

      // Sort primary event ticket packages.
      const sortedEventTicketPackages = [...this.event.ticketPackages].sort(
        (item1: TicketPackage, item2: TicketPackage) => {
          return item1.price - item2.price;
        }
      );
      this.event.ticketPackages = [...sortedEventTicketPackages].reverse();

      // Sort all child event ticket packages
      const addons = this.event.childEvents;

      addons.forEach((childEvent: Event) => {
        const childEventPackage = childEvent.ticketPackages;
        childEventPackage.sort((item1, item2) => {
          return item1.price - item2.price;
        });
        childEventPackage.reverse();

        childEvent.ticketPackages = childEventPackage;
      });

      // Update ticketPackages if event Sold out.
      if (event.soldOut) {
        this.event.ticketPackages.forEach((tp: TicketPackage) => {
          tp.soldOut = true;
        });
      }

      if (event.childEvents.length && event.childEvents[0].soldOut) {
        this.event.childEvents[0].ticketPackages.forEach((tp: TicketPackage) => {
          tp.soldOut = true;
        });
      }

      // Set event in store.
      this.$store.commit('updateEvent', this.event);
      // Set Stripe JS SDK in store.
      await this.$store.dispatch('setStripe', {
        countryCode: this.event.country as Country,
        accountId: this.event.stripeConnectedAccountId
      });

      // Set whitelabel vairables into state.
      let whitelabelSettings: WhiteLabelSettings = this.event.settings.whiteLabel || null;

      // Fallback for Rotary Club of Oakville Electric Car Lottery
      if (this.event.id === '8dba0572-a9d9-4bb6-b134-d819b80bbee5') {
        whitelabelSettings = {
          enabled: true,
          buttonColor: '#1D4781'
        };
      }
      this.$store.commit('addCustomization', whitelabelSettings);

      // Grab only the first availible Addon for MVP.
      if (this.event.childEvents.length >= 1) {
        // Set Event Checkout Type
        await this.$store.commit('updateEventCategory', EventCheckoutType.multiraffle);
        // Set Addon
        await this.$store.commit('updateAddon', this.event.childEvents[0]);
      }

      if (this.event?.goldrush) {
        // The Goldrush property is only found on Goldrush raffles
        await this.$store.dispatch('fetchGrMasterEvent', this.event.organizationId);
        await this.$store.commit('updateEventCategory', EventCheckoutType.goldrush);
      }

      // ABSPCA display discount flow.
      if (
        this.event.id === 'e38d3cc7-7a43-4ab9-a915-5295233cac4e' ||
        // For testing
        this.event.id === 'fe0bfbc8-d887-41b0-9df2-377c1b465b44'
      ) {
        await this.$store.commit('isSpecialEvent', true);
      }

      this.geoLocationEnabled = event.settings.geoLocation || false;
    } catch (error: any) {
      this.errorCode = error.message;
    }

    // keep the loading spinner going if geo-location is enabled
    if (!this.geoLocationEnabled) {
      this.loadingPage = false;
    }
  }

  eventCategoryTypeRedirect(event: Event) {
    // Check if Multi-Raffle
    if (event.childEvents.length !== 0) {
      if (this.$route.name !== 'Raffle') {
        this.$router.push({ name: 'Raffle', query: { ...this.$route.query } });
        return;
      } else {
        return;
      }
    }

    // Check if Goldrush
    if (Object.prototype.hasOwnProperty.call(event, 'goldrush')) {
      if (this.$route.name !== 'Goldrush') {
        this.$router.push({ name: 'Goldrush', query: { ...this.$route.query } });
        return;
      } else {
        return;
      }
    }

    // Set to Single as default
    if (this.$route.name !== 'Single') {
      this.$router.push({ name: 'Single', query: { ...this.$route.query } });
    } else {
      return;
    }
  }

  userLocationFound(isInLocation: boolean, error: Error) {
    if (error) {
      this.errorCode = CheckoutErrorCodes.LocationNotFound;
      this.locationLink = true;
    } else {
      if (!isInLocation) {
        const userLocation = this.$refs.userLocation as unknown as IUserLocation;

        // Find the full name of the provinces and update state
        const provinces = CountryProvinceAgeModel[this.event.country as Country].provinceState;

        const requiredProvince = provinces.find((province) => province.code === this.event.province);
        const userLocationProvince = provinces.find((province) => province.code === userLocation.userLocationProvince);

        if (requiredProvince && userLocationProvince) {
          this.$store.commit('addLocation', { required: requiredProvince.code, found: userLocationProvince.code });
          this.errorCode = CheckoutErrorCodes.LocationFoundNotInProvince;
        } else {
          this.errorCode = CheckoutErrorCodes.LocationNotInProvince;
        }

        this.locationLink = true;
      }

      this.loadingPage = false;
    }
  }
}
</script>

<style lang="scss">
html,
body {
  font-family: $body;
  height: 100vh;
  margin: 0;
}

body {
  touch-action: manipulation;
}

#page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
}

.big-spinner.spinner-border {
  height: 10rem;
  width: 10rem;
}

@media screen and (max-width: 36em) {
  .rb-alert {
    margin-left: 0 !important;
  }
}

// For some reason our default bootstrap checkboxes are a circle. This eases the rounding so its now a rounded square.
// Someone may know a better way to fix this
.custom-checkbox .custom-control-label::before {
  border-radius: 4px !important;
}
</style>
