<template>
  <ValidationObserver ref="observer">
    <!--Toggle-->
    <ValidationProvider v-slot="shippingAddressToggle" name="shipping address toggle">
      <b-form-group id="input-shipping-address-toggle-check" class="mb-5" label-for="input-shipping-address-toggle">
        <b-form-checkbox
          v-model="checkedShippingAddress"
          :state="getValidationState(shippingAddressToggle)"
          aria-describedby="input-shipping-address-toggle-check-feedback"
          :unchecked-value="false"
        >
          <p class="mb-1">Use billing address as shipping address</p>
        </b-form-checkbox>
        <b-form-invalid-feedback id="input-shipping-address-toggle-check-feedback">{{
          shippingAddressToggle.errors[0]
        }}</b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>

    <b-form v-if="!checkedShippingAddress" class="mb-5 payment-form" @submit.prevent>
      <h2 class="mb-5">Shipping Address</h2>
      <!-- Address -->
      <ValidationProvider v-slot="shippingAddress" name="shipping address" rules="required|min:3">
        <b-form-group id="shipping-address-input" :label="$t('Address')" label-for="input-shipping-address">
          <b-form-input
            id="input-shipping-address"
            v-model="shippingInformation.address"
            :state="getValidationState(shippingAddress)"
            aria-describedby="input-shipping-address-feedback"
            type="text"
          ></b-form-input>
          <b-form-invalid-feedback id="input-shipping-address-feedback">{{
            shippingAddress.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <!-- City -->
      <ValidationProvider v-slot="shippingCity" name="shipping city" rules="required|min:3">
        <b-form-group id="input-shipping-city" :label="$t('City')" label-for="input-shipping-city">
          <b-form-input
            id="input-shipping-city"
            v-model="shippingInformation.city"
            :state="getValidationState(shippingCity)"
            aria-describedby="input-shipping-city-feedback"
            type="text"
          ></b-form-input>
          <b-form-invalid-feedback id="input-shipping-city-feedback">{{
            shippingCity.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <CountryInput
        v-model="shippingInformation.country"
        :validate-postal-code-location="!eventDetails.disablePostalCheckV2"
      />

      <ProvinceStateSelect
        v-if="shippingInformation.country"
        :country="shippingInformation.country"
        :validate-postal-code-location="false"
        @province-input="handleProvinceInput"
      />

      <PostalZipInput
        v-if="shippingInformation.country"
        v-model="shippingInformation.postal"
        :country="shippingInformation.country"
        :province="shippingInformation.province"
        :validate-postal-code-location="!eventDetails.disablePostalCheckV2"
        parent-name="shipping"
      />
    </b-form>
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import ProvinceStateSelect from '@/components/ProvinceStateSelect.vue';
import PostalZipInput from '@/components/PostalZipInput.vue';
import CountryInput from '@/components/CountryInput.vue';

import ProjectMixin from '@/project-mixin';

interface Flags {
  [k: string]: boolean | null;
}

export interface ShippingInformationData {
  province: string | null;
  postal: string | null;
  address: string | null;
  city: string | null;
  country: string | null;
}

@Component({
  components: {
    ValidationProvider,
    ProvinceStateSelect,
    PostalZipInput,
    CountryInput
  }
})
export default class ShippingInformation extends Mixins(ProjectMixin) {
  private shippingSimilarToBilling = false;
  private event = this.$store.state.event;

  shippingInformation: ShippingInformationData = {
    province: null,
    postal: null,
    address: null,
    city: null,
    country: this.$store.state.event.country
  };

  get eventDetails() {
    return this.event;
  }

  get checkedShippingAddress() {
    return this.shippingSimilarToBilling;
  }

  set checkedShippingAddress(value: boolean) {
    this.shippingSimilarToBilling = value;
    this.shippingInformation.province = null;
    this.shippingInformation.postal = null;
    this.shippingInformation.city = null;
    this.shippingInformation.address = null;
  }

  @Watch('shippingInformation', { immediate: true, deep: true })
  shippingDataUpdated(newData: ShippingInformationData) {
    this.shippingInformation = newData;
    this.shippingDataChanged();
  }

  @Emit('shipping-data-changed')
  shippingDataChanged() {
    return this.shippingInformation;
  }

  handleProvinceInput(value: string) {
    this.shippingInformation.province = value;
  }

  getValidationState({ dirty, validated, valid = null }: Flags) {
    return dirty || validated ? valid : null;
  }
}
</script>
