<template>
  <div class="addon-page">
    <div class="raffle-title">
      <h2>{{ addon.name }}</h2>
      <CircleNav v-if="canViewMultiAddOnFlowOrgs" />
    </div>
    <InSupport />
    <h4 class="text-regular mb-3">{{ $t('ticketPackages') }}</h4>
    <div class="ticket-packages">
      <TicketPackage
        v-for="ticketPackage of addon.ticketPackages"
        :key="ticketPackage.series"
        :ticket-package="ticketPackage"
        :event-id="addon.id"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import InSupport from '@/components/InSupport.vue';
import TicketPackage from '@/components/TicketPackage.vue';
import CircleNav from '@/components/CircleNav.vue';

import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

@Component({
  components: { TicketPackage, InSupport, CircleNav }
})
export default class Addon extends Vue {
  canViewMultiAddOnFlowOrgs = false;

  get addon() {
    return this.$store.state.addon;
  }

  async mounted() {
    this.canViewMultiAddOnFlowOrgs = await unleashFeatureEnabled(UnleashKeys.ViewMultiAddOnFlowOrgs);
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $heading;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0 1.5rem;
}

.ticket-packages {
  margin-bottom: 2rem;

  .tickets {
    &:last-child {
      border-bottom: 0;
    }
  }
}

.raffle-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// iPad Portrait
@media screen and (min-width: 48rem) {
  h2 {
    font-size: 3rem;
    margin: 2rem 0 3rem;
  }

  .ticket-packages {
    margin-bottom: 4rem;
  }
}
</style>
