<template>
  <div>
    <RbAlert v-if="errorMessage" variant="danger">{{ errorMessage }}</RbAlert>
    <div v-if="isMultiraffle && $route.name !== 'Confirm'" class="purchase-bar">
      <div v-if="$route.name !== 'Raffle'" class="purchase-bar__back" @click="updateCurrentPage('back')">
        <router-link v-if="currentPage === 2" :to="{ path: '/raffle', query: { ...$route.query } }">
          <RbLoadingButton variant="outline-secondary"> <b-icon-chevron-left /> Back </RbLoadingButton>
        </router-link>
        <router-link v-else :to="{ path: $route.path, query: { ...$route.query } }">
          <RbLoadingButton variant="outline-secondary"> <b-icon-chevron-left /> Back </RbLoadingButton>
        </router-link>
      </div>
      <div class="purchase-bar__total-icon">
        <b-icon-cart-fill />
        <span class="purchase-bar--total">{{
          $i18n.locale === 'fr'
            ? formatCurrency(shoppingCart.totalCart(), {
                precision: 0,
                locale: 'fr',
                currency: $store.state.event.currency.toUpperCase()
              })
            : formatCurrency(shoppingCart.totalCart(), {
                precision: 0,
                currency: $store.state.event.currency.toUpperCase()
              })
        }}</span>
      </div>
      <div class="purchase-bar__next" @click="updateCurrentPage('next')">
        <router-link :to="{ path: nextPage, query: { ...$route.query } }">
          <RbLoadingButton variant="success" :disabled="disableNext || (shoppingCart.totalCents === 0 && isLastAddon)">
            Next <b-icon-chevron-right />
          </RbLoadingButton>
        </router-link>
      </div>
    </div>
    <div v-else class="purchase-button">
      <div v-if="!isMultiraffle" class="purchase-bar__total-icon">
        <b-icon-cart-fill />
        <span class="purchase-bar--total">{{
          $i18n.locale === 'fr'
            ? formatCurrency(shoppingCart.totalCart(), {
                precision: 0,
                locale: 'fr',
                currency: $store.state.event.currency.toUpperCase()
              })
            : formatCurrency(shoppingCart.totalCart(), {
                precision: 0,
                currency: $store.state.event.currency.toUpperCase()
              })
        }}</span>
      </div>
      <div :class="{ 'w-100': $route.name === 'Confirm' }">
        <RbLoadingButton variant="success" :disabled="purchaseDisabled" @click.native="toPayment">
          {{ $t('Continue to payment') }}
        </RbLoadingButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { EventCheckoutType } from '@/lib/schema/event.schema';
import ProjectMixin from '@/project-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import config from '@/config';
import TermsAndPolicy from '@/components/TermsAndPolicy.vue';
import RbLoadingButton from '@/components/RbLoadingButton.vue';
import RbAlert from '@/components/RbAlert.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

@Component({ components: { TermsAndPolicy, RbLoadingButton, RbAlert } })
export default class PurchaseBar extends Mixins(ProjectMixin) {
  @Prop({ required: true })
  disableNext!: boolean;

  errorMessage = '';
  memberRequired = this.$store.state.event.supportStatsForceSelectionEnabled;
  canViewMultiAddOnFlowOrgs = false;

  get nextPage(): string {
    switch (this.$route.name) {
      case 'Raffle':
        return '/raffle';

      case 'Addon':
        return '/addon';

      case 'Single':
        return '/confirm';

      default:
        return '/raffle';
    }
  }

  async mounted() {
    this.canViewMultiAddOnFlowOrgs = await unleashFeatureEnabled(UnleashKeys.ViewMultiAddOnFlowOrgs);
  }

  get currentPage() {
    return this.$store.state.currentPage;
  }

  get event() {
    return this.$store.state.event;
  }

  get isSingle() {
    return this.$store.state.category === EventCheckoutType.single;
  }

  get isMultiraffle() {
    return this.$store.state.category === EventCheckoutType.multiraffle;
  }

  get purchaseDisabled() {
    return this.shoppingCart.totalCents === 0;
  }

  get isLastAddon() {
    return (
      this.$route.name !== 'Raffle' && // needed for non-multi-addon-flow
      this.$store.state.event.childEvents[this.$store.state.event.childEvents.length - 1].id ===
        this.$store.state.addon.id
    );
  }

  updateCurrentPage(direction: string) {
    if (direction === 'back') {
      if (this.canViewMultiAddOnFlowOrgs) {
        this.$store.commit('updateCurrentPage', this.$store.state.currentPage - 1);
        if (this.$store.state.currentPage > 1) {
          this.$store.commit('updateAddon', this.$store.state.event.childEvents[this.$store.state.currentPage - 2]);
        }
      } else {
        this.$router.push({ name: 'Raffle', query: { ...this.$route.query } });
      }
    } else if (direction === 'next') {
      if (!this.canViewMultiAddOnFlowOrgs) {
        if (this.$route.name === 'Raffle') {
          this.$router.push({ name: 'Addon', query: { ...this.$route.query } });
        } else {
          this.$router.push({ name: 'Confirm', query: { ...this.$route.query } });
        }
      } else if (this.event.childEvents && this.currentPage < this.event.childEvents.length + 1) {
        if (this.$route.name === 'Raffle') {
          this.$router.push({ name: 'Addon', query: { ...this.$route.query } });
        }
        this.$store.commit('updateCurrentPage', this.$store.state.currentPage + 1);
        this.$store.commit('updateAddon', this.$store.state.event.childEvents[this.$store.state.currentPage - 2]);
      } else {
        this.$store.commit('updateCurrentPage', this.$store.state.currentPage + 1);
        this.$router.push({ name: 'Confirm', query: { ...this.$route.query } });
      }
    }
  }

  toPayment() {
    // Do simple validation check
    if (this.shoppingCart.totalCart() < 10 && this.$store.state.category === EventCheckoutType.goldrush) {
      this.errorMessage = 'There is a minimum purchase of $10 for this raffle.';
    } else {
      // GA AddtoCart
      // Build array of current cart
      const products = this.shoppingCart.items().map((tp) => {
        const price = tp.priceCents / 100;

        return {
          // Linter complains about non-camelCase
          /* eslint-disable */
          item_id: tp.id,
          item_name: this.$store.state.event.name,
          price: price,
          item_category: this.$store.state.event.category,
          quantity: tp.quantity,
          item_variant: `${tp.numTickets} for $${price}`,
          index: 0
          /* eslint-enable */
        };
      });

      if (['test', 'production'].includes(config.ENV)) {
        if (this.$gtm.enabled()) {
          (window as any).dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              currency: this.$store.state.event.currency.toUpperCase(),
              value: this.shoppingCart.totalCart(),
              items: products
            }
          });
        }
      }

      this.$router.push({ name: 'Payment', query: { ...this.$route.query } });
    }
  }
}
</script>

<style lang="scss" scoped>
.purchase-button,
.purchase-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 2px solid $slate;

  &__total-icon {
    font-family: $heading;
    font-weight: 600;
    font-size: 2rem;
    color: #000000;
  }

  &--total {
    padding-left: 0.5rem;
  }
}

.purchase-button {
  button {
    font-family: $body;
    font-size: 1.25rem;
    width: 100%;
    padding: 1rem;

    background-color: var(--buttonColor);
    border-color: var(--buttonColor);

    &:active,
    &:hover:not(.disabled) {
      color: var(--buttonColor);
      background-color: $white !important;
      border-color: var(--buttonColor) !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &.disabled {
      background-color: $smoke;
      border-color: $smoke;
    }
  }
}

.purchase-bar__next {
  button {
    background-color: var(--buttonColor);
    border-color: var(--buttonColor);

    &:hover:not(.disabled),
    &:active {
      color: var(--buttonColor) !important;
      background-color: $white !important;
      border-color: var(--buttonColor) !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &.disabled {
      background-color: $smoke;
      border-color: $smoke;
    }
  }

  span.disabled {
    background-color: $smoke;
    border-color: $smoke;
  }
}

@media screen and (max-width: 25em) {
  .purchase-button {
    button {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }

  .purchase-bar__total-icon {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 48em) {
  .purchase-button,
  .purchase-bar {
    padding: 1rem;
    border: 1px solid $slate;

    &__back,
    &__next {
      .btn {
        font-size: 1rem;
      }
    }
  }
}
</style>
